<template>
<div>
  <div class="cover-page h-72">
  </div>
  <div class="-m-28	w-11/12 mx-auto mb-16 profile-header">
    <div class="relative">
      <div class="cover-container-nav rounded-t-lg">
        <img src="../assets/images/footer.jpg" alt="user-profile-cover" class="h-20 w-1/5 responsive block">
      </div>
      <div class="w-1/4 profile-img-container">
        <div class="flex">
          <avatar class="user-profile-img" :username="`${data.fname} ${data.lname}`"></avatar>
        </div>
        <div>
          <p class="text-blue text-center">{{data.fname}} {{data.lname}}</p>
          <p class="text-center">{{data.email}}</p>
        </div>
      </div>
    </div>
    <vs-tabs class="mt-24 pb-10" position="left">
      <vs-tab icon="perm_identity" label="Profile Data">
        <div class="vs-row">
          <h2 class="vs-col w-1/2">Profile Data</h2>
          <span v-if="!editFlag" @click="editFlag=true" class="vs-col w-1/2 cursor-pointer text-blue flex justify-end">
            <vs-icon size="20px" class="self-center" color="#00DCDC" icon="edit"></vs-icon>
            <span class="self-center">Edit</span>
          </span>
        </div>
        <div class="vs-row pt-4 profile-data">
          <vs-card>
            <p class="text-blue">Full Name:</p>
            <p v-if="!editFlag">{{data.fname}} {{data.lname}}</p>
            <vs-input v-if="editFlag" class="mb-4" color="dark" placeholder="Full Name" v-model="data.fname"/>
            <vs-input v-if="editFlag" color="dark" placeholder="Full Name" v-model="data.lname"/>
          </vs-card>
          <vs-card>
            <p class="text-blue">Email:</p>
            <p v-if="!editFlag">{{data.email}}</p>
            <vs-input v-if="editFlag" color="dark" placeholder="Email" v-model="data.email"/>
          </vs-card>
          <vs-card>
            <p class="text-blue">Phone No:</p>
            <p v-if="!editFlag">{{data.mobile}}</p>
            <vs-input v-if="editFlag" color="dark" placeholder="Phone No" v-model="data.mobile"/>
          </vs-card>
          <div v-if="editFlag" class="vs-row w-full">
            <vs-button @click="saveData()" class="btn md:w-1/4 w-1/3" color="#F89416" text-color="#fff">Save</vs-button>
          </div>
        </div>
      </vs-tab>
      <vs-tab icon="pending_actions" label="My Schedule">
        <h2>My Schedule</h2>
      </vs-tab>
      <vs-tab @click="manageMemebers()" icon="settings" label="Manage Kids">
        <h2>Manage Kids</h2>
      </vs-tab>
      <vs-tab icon="location_on" label="My Addresses">
        <addresses></addresses>
      </vs-tab>
      <vs-tab icon="location_on" label="Change password">
        <reset-password></reset-password>
      </vs-tab>
      <vs-tab icon="history" label="History">
        <h2>History</h2>
      </vs-tab>
    </vs-tabs>
  </div>
</div>
</template>

<script>
import Addresses from './Addresses.vue'
import ResetPassword from './ResetPassword.vue'
import axios from '../axios.js'
import Avatar from 'vue-avatar'

export default {
  data () {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      editFlag: false,
      data: JSON.parse(localStorage.getItem('user_data'))
    }
  },
  methods: {
    manageMemebers () {
      this.$router.push({
        name: 'manage-members'
      })
    },
    saveData () {
      const formData = new FormData()
      formData.append('fname', this.data.fname)
      formData.append('lname', this.data.lname)
      formData.append('mobile', this.data.mobile)
      formData.append('email', this.data.email)
      axios.post('v1/user/updateProfile', formData, {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.editFlag = false
          this.$vs.loading.close()
        }
      })
    }
  },
  components: {
    Addresses,
    ResetPassword,
    Avatar
  },
  created () {
  }
}
</script>

<style>
.profile-data .vs-card--content {
  margin-bottom: -10px !important;
}
.image-upload {
  right: 45%;
  bottom: 32%;
}
.camera-icon {
  width: 35px !important;
  height: 35px !important;
  background-color: #fff;
  border: 1px solid lightgrey;
  border-radius: 50%;
}
.image-upload>input {
  display: none;
}
.btn {
  padding: 7px 15px;
}
.activeChild .vs-icon-primary {
  color: white !important;
}
.vs-icon-primary {
  color: #00DCDC !important;
}

.cover-page {
  background-image: url('../assets/images/footer.jpg');
  background-size: 100% 100%;
  background-position: center;
}
.profile-img-container {
  align-items: center;
  position: absolute;
  bottom: -6rem;
  box-shadow: 0 -11px 16px 0 rgb(0 0 0 / 20%);
  width: 20%;
  /* background: white; */
  justify-content: space-between;
}
[dir="rtl"] .user-profile-img {
  margin-right: 38%;
  width: 60px !important;
  height: 60px !important;
  border: 3px solid #00DCDC; 
}
[dir="ltr"] .user-profile-img {
  margin-left: 38%;
  width: 60px !important;
  height: 60px !important;
  border: 3px solid #00DCDC; 
}
.vs-dropdown--custom {
  padding: 0px !important;
}
.vs-tabs-primary .con-ul-tabs .activeChild button {
  color: white !important;
}
.vs-tabs-primary .con-ul-tabs button:not(:disabled):hover {
  color: black !important;
}
.con-vs-tabs >.con-ul-tabs {
  float: left;
  width: 24% ;
  height: 100%;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  display: block;
}
.vs-tabs--li {
  border-radius: 10px;
  width: 90%;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  margin: 3px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 9px;
  margin-bottom: 9px;
}
.con-slot-tabs {
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  width: 70% ;
  margin-left: 10px;
}
.activeChild {
  background: #00DCDC;
}
.vs-tabs-primary .con-ul-tabs .activeChild button {
  color: white !important;
}
.vs-tabs-primary .con-ul-tabs button:not(:disabled):hover {
  color: black !important;
}
.con-slot-tabs {
width: 95%;
}
</style>