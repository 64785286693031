<template>
	<div>
    <div class="vx-row w-full mb-2">
      <div class="vx-col w-full items-end">
        <div class="flex justify-end">
          <vs-button radius class="rounded-full" icon="add" @click="openModal"></vs-button>
        </div>
      </div>
    </div>
		<div class="vs-row">
			<div class="vs-col w-1/2" v-for="address in addressess" :key="address.index">
				<div class="border-2 border-slate-300	rounded-md p-6 mr-3 mb-4">
					<h2>{{address.title}}</h2>
					<h3>{{address.area_name}}, {{address.block}}, {{address.street}}, {{address.building}}</h3>
					<div class="w-full vs-col flex justify-end divide-x divide-gray-300 text-gray-500">
						<span @click="editAddress(address)" class="cursor-pointer px-2 text-blue flex">
							<vs-icon size="20px" class="self-center" color="#00DCDC" icon="edit"></vs-icon>
							<span class="self-center">Edit</span>
						</span>
						<span @click="removeAddress(address)" class="cursor-pointer px-2 text-red-600 flex">
							<vs-icon size="20px" class="self-center" icon="delete"></vs-icon>
							<span class="self-center">Remove</span>
						</span>
					</div>
				</div>
			</div>
		</div>
    <vs-prompt
      @close="closeModal"
      :active.sync="addressModal"
      title="Add Address"
      :buttons-hidden="true">
      <VuePerfectScrollbar :is="'div'" class="scroll-area" :settings="settings">
        <form class="mb-10">
          <div class="vs-row">
            <div class="vs-col w-full mb-6">
              <div class="mb-2">
                <label class="mb-6 blue">Title</label>
              </div>
              <div>
                <vs-input class="w-full" placeholder="Name" v-model="address.title"/>
              </div>
            </div>
            <div class="vs-col w-full mb-6">
              <div class="mb-2">
                <label class="mb-6 blue">City</label>
              </div>
              <div class="w-full">
                <vs-select
                  class="selectExample w-full"
                  v-model="address.area_id"
                  >
                  <vs-select-item :key="index" :value="item.id" :text="item.gov" v-for="item,index in areas" />
                </vs-select>
              </div>
            </div>
            <div class="vs-col w-full mb-6">
              <div class="mb-2">
                <label class="mb-6 blue">Area</label>
              </div>
              <div v-if="address.area_id" class="w-full">
                <vs-select
                  class="selectExample w-full"
                  v-model="address.area_name"
                  >
                  <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="item,index in areasDic[address.area_id]" />
                </vs-select>
              </div>
            </div>
            <div class="vs-col w-full mb-6">
              <div class="mb-2">
                <label class="mb-6 blue">Block</label>
              </div>
              <div>
                <vs-input class="w-full" placeholder="Block" name="Block" v-model="address.block"/>
                <span class="text-danger text-sm" v-show="errors.has('Block')">{{ errors.first('Block') }}</span>
              </div>
            </div>
            <div class="vs-col w-full mb-6">
              <div class="mb-2">
                <label class="mb-6 blue">Street</label>
              </div>
              <div>
                <vs-input class="w-full" placeholder="street" name="street" v-model="address.street"/>
                <span class="text-danger text-sm" v-show="errors.has('street')">{{ errors.first('street') }}</span>
              </div>
            </div>
            <div class="vs-col w-full mb-6">
              <div class="mb-2">
                <label class="mb-6 blue">Building</label>
              </div>
              <div>
                <vs-input class="w-full" placeholder="building" name="building" v-model="address.building"/>
                <span class="text-danger text-sm" v-show="errors.has('building')">{{ errors.first('building') }}</span>
              </div>
            </div>
            <div class="vs-col w-full mb-6">
              <div class="mb-2">
                <label class="mb-6 blue">Floor</label>
              </div>
              <div>
                <vs-input class="w-full" placeholder="floor" name="floor" v-model="address.floor"/>
                <span class="text-danger text-sm" v-show="errors.has('floor')">{{ errors.first('floor') }}</span>
              </div>
            </div>
            <div class="vs-col w-full mb-6">
              <div class="mb-2">
                <label class="mb-6 blue">Apartment</label>
              </div>
              <div>
                <vs-input class="w-full" placeholder="apartment" name="apartment" v-model="address.apartment"/>
                <span class="text-danger text-sm" v-show="errors.has('apartment')">{{ errors.first('apartment') }}</span>
              </div>
            </div>
            <div class="vs-col w-full mb-6">
              <div class="mb-2">
                <label class="mb-6 blue">Avenue</label>
              </div>
              <div>
                <vs-input class="w-full" placeholder="avenue" name="avenue" v-model="address.avenue"/>
                <span class="text-danger text-sm" v-show="errors.has('avenue')">{{ errors.first('avenue') }}</span>
              </div>
            </div>
            <div class="vs-col w-full">
              <div class="mb-2">
                <label class="mb-6 blue">Additional Directions</label>
              </div>
              <div>
                <vs-input class="w-full mb-6" placeholder="additional direction" name="additional direction" v-model="address.additional_direction"/>
                <span class="text-danger text-sm" v-show="errors.has('additional direction')">{{ errors.first('additional direction') }}</span>
              </div>
            </div>
          </div>
        </form>
      </VuePerfectScrollbar>
      <div class="p-4">
        <vs-button v-if="type === 'add'" type="filled" color="primary" @click="addAddressInfo" class="w-full  float-right m-5 block">{{$t('Add')}}</vs-button>
        <vs-button v-else type="filled" color="primary" @click="editAddressInfo" class="w-full  float-right m-5 block">{{$t('Edit')}}</vs-button>
      </div>
    </vs-prompt>
	</div>
</template>

<script>
import axios from '../axios.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
	data () {
		return {
      type: '',
			headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
			addressess: [],
      address: {
        area_id: '',
        area_name: ''
      },
      areasDic: {},
      addressModal: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
      areas: []
		}
	},
	methods: {
		loadAddressess () {
			this.$vs.loading()
			const user = JSON.parse(localStorage.getItem('user_data'))
      axios.post('v1/user/list_address', {user_id: user.user_id}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.addressess = response.data.payload
          this.$vs.loading.close()
        }
      })
		},
    openModal () {
      this.type = 'add'
      this.address = {}
      this.addressModal = true
    },
    loadAreas () {
      axios.get('v1/areaList', {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.areas = response.data.payload
          response.data.payload.forEach(element => {
            this.areasDic[element.id] = element.areas
          })
        }
      })
    },
		removeAddress (address) {
			this.$vs.loading()
      axios.post('v1/user/delete_address', {address_id: address.id}, {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.loadAddressess()
          this.$vs.loading.close()
        }
      })
		},
    addAddressInfo () {
      this.$vs.loading()
      axios.post('v1/user/add_address', this.address, {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.addressModal = false
          this.loadAddressess()
          this.$vs.loading.close()
        }
      })
    },
    editAddressInfo () {
      this.$vs.loading()
      this.address.address_id = this.address.id
      axios.post('v1/user/edit_address', this.address, {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.addressModal = false
          this.loadAddressess()
          this.$vs.loading.close()
        }
      })
    },
    closeModal () {
      this.addressModal = false
    },
		editAddress (address) {
      this.address = address
      this.addressModal = true
		},
	},
  components: {
    VuePerfectScrollbar
  },
	created () {
    this.loadAreas()
		this.loadAddressess()
	}
}
</script>