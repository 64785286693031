<template>
  <div>
    <vs-card>
      <div class="mb-8">
        <vs-input type="password" v-validate="'required'" color="dark" label-placeholder="Old password" name="old password" v-model="data.old_password"/>
        <span class="text-danger text-sm" v-show="errors.has('old password')">{{ errors.first('old password') }}</span>
      </div>
      <div class="mb-8">
        <vs-input ref="password" type="password" v-validate="'required'" color="dark" label-placeholder="New Password" name="new Password" v-model="data.password"/>
        <span class="text-danger text-sm" v-show="errors.has('new Password')">{{ errors.first('new Password') }}</span>
      </div>
      <div class="mb-8">
        <vs-input type="password" v-validate="{ required: true, confirmed:'password'}" color="dark" label-placeholder="Confirm password" name="confirm password" v-model="data.confirm_password"/>
        <span class="text-danger text-sm" v-show="errors.has('confirm password')">{{ errors.first('confirm password') }}</span>
      </div>
    </vs-card>
    <div class="vs-row w-full">
      <vs-button primary class="mb-4 float-right btn md:w-1/4 w-1/3" text-color="#fff" @click="resetPassword">Save</vs-button>
    </div>
  </div>
</template>

<script>
import axios from '../axios.js'

export default {
  data () {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      data: {}
    }
  },
  methods: {
    resetPassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const user = JSON.parse(localStorage.getItem('user_data'))
          this.data.mobile = user.mobile
          axios.post('v1/resetPassword', this.data, {headers: this.headers})
          .then(response => {
            console.log(response.data)
            if (response.data.code == 0) {
              console.log(111)
              this.$vs.loading.close()
              this.$vs.notify({
                color:'danger',
                title: 'Error',
                text: response.data.message,
                position: 'top-center'
              })
            } else {
              this.$vs.notify({
                color:'success',
                title: 'Success',
                text: 'Password update successfully.',
                position: 'top-center'
              })
              this.data = {}
            }
          })
        }
      })
    }
  }
}
</script>